





























































import { Action, Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import * as types from '@/store/types';
import { User } from '@/store/user/models';

const namespace: string = 'user';

@Component
export default class UsersOverview extends Vue {
  @Action(types.FETCH_USERS, { namespace }) fetchUsers: any;

  @Action(types.SET_ADMIN_RIGHT, { namespace }) setAdminRight: any;

  @Action(types.UNSET_ADMIN_RIGHT, { namespace }) unsetAdminRight: any;

  @Action(types.SET_UPLOAD_RIGHT, { namespace }) setUploadRight: any;

  @Action(types.UNSET_UPLOAD_RIGHT, { namespace }) unsetUploadRight: any;

  @Getter(types.GET_USERS, { namespace }) getUsers: any;

  @Getter(types.GET_USER, { namespace }) getUser: any;

  public headers = [
    {
      text: 'Email',
      value: 'email',
    },
    {
      text: 'Username',
      value: 'username',
    },
    {
      text: this.$t('is_user_admin'),
      value: 'isAdmin',
    },
    {
      text: this.$t('is_user_allowed_to_upload'),
      value: 'isAllowedToUpload',
    },
  ];

  public search = '';

  async created() {
    await this.fetchUsers();
  }

  private async makeUserAdmin(id: string, isAdmin: boolean) {
    if (isAdmin) {
      await this.setAdminRight(id);
      return;
    }
    await this.unsetAdminRight(id);
  }

  private async allowUserToUploadPackages(id: string, isAllowedToUpdate: boolean) {
    if (isAllowedToUpdate) {
      await this.setUploadRight(id);
      return;
    }
    await this.unsetUploadRight(id);
  }
}
