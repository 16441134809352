










































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Layout from '@/components/shared/Layout.vue';
import GeneralSettings from '@/components/adminPanel/GeneralSettings.vue';
import UsersOverview from '@/components/adminPanel/UsersOverview.vue';
import InviteUser from '@/components/adminPanel/InviteUser.vue';
import SendAllUsers from '@/components/adminPanel/SendAllUsers.vue';
import * as types from '@/store/types';

const namespace: string = 'user';

// eslint-disable-next-line no-use-before-define
@Component({
  components: {
    Layout,
    UsersOverview,
    GeneralSettings,
    InviteUser,
    SendAllUsers,
  },
})
export default class AdminPanel extends Vue {
  @Getter(types.GET_USER, { namespace }) getUser: any;

  public tabs = null;

  public displayComponents = [
    {
      name: 'GeneralSettings',
      icon: 'mdi-cog',
      text: 'general_settings',
    },
    {
      name: 'InviteUser',
      icon: 'mdi-account-multiple-plus-outline',
      text: 'invite_user',
    },
    {
      name: 'UsersOverview',
      icon: 'mdi-account-multiple',
      text: 'users',
    },
    {
      name: 'SendAllUsers',
      icon: 'mdi-email',
      text: 'notify_users',
    },
  ];

  created() {
    if (!this.getUser.isAdmin) {
      this.$router.push('/');
    }
  }
}
